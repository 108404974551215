import { render, staticRenderFns } from "./default.vue?vue&type=template&id=8dbc2cfe&scoped=true"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=8dbc2cfe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dbc2cfe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GeneralUtilsLoader: require('/app/components/general/utils/Loader.vue').default,LayoutNavBarTop: require('/app/components/layout/NavBarTop.vue').default,LayoutNavBarTopFocus: require('/app/components/layout/NavBarTopFocus.vue').default,GeneralUtilsSnackbar: require('/app/components/general/utils/Snackbar.vue').default,LayoutFooterMain: require('/app/components/layout/FooterMain.vue').default})
